import { HttpErrorResponse } from '@angular/common/http';

import _get from 'lodash-es/get';

export function getErrorMessage(er: HttpErrorResponse) {
  return (
    _get(er, 'error.Message') ||
    _get(er, 'error.message') ||
    _get(er, 'message') ||
    er
  );
}
